import React, { useContext, useRef } from 'react';
import { Formik } from 'formik';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { getHeaders } from '../../request';
import { validationDadosGerais } from './informacoes-gerais/validationSchema';
import validationSchemaDadosExame from './dados-exame/validationSchema';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import ModalDadosGerais from './informacoes-gerais';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import alerta from '../../signals/alerta';
import { MODALIDADE, PERFIL } from '../../common/Constants';
import { anexos } from '../../signals/envio-imagens';
import { temPerfilRBAC } from '../../security/acl';
import usuario from '../../signals/usuario';
import Utils from '../../utils';

const initialValues = {
    equipamento: null,
    observacao: '',
    requisicao: '',
    validarImagem: false,
    dadosExame: {
        grauDesconfortoAparelhoPolissonografia: null,
        especificacaoCausaDesconforto: '',
        horaAdormecer: null,
        tempoDemoraIniciarSono: null,
        quantidadeDespertouNoite: null,
        tempoDormiuNoite: null,
        grauComparacaoSonoHabitual: null
    },
    anexo: [{}],
    anexoExterno: {}
};

const EnvioImagens = ({ modalOpen, resetFormulario, solicitacao = {}, callbackFinalizarEnvio }) => {
    useSignals();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const exameEnviado = useSignal(null);
    const progresso = useSignal(false);
    const gerarTermo = useSignal(false);
    const gerarProtocolo = useSignal(false);
    const paciente = useSignal(null);
    const equipamentos = useSignal([]);
    const ufSolicitante = useSignal(null);
    const erroEquipamento = useSignal(false);
    const stepAtual = useSignal(0);
    const mensagemProgresso = useSignal(strings.mensagemEnviandoImagens);

    const formikRef = useRef();

    initialValues.resultadoExames = solicitacao.resultadoExames || '';

    const steps = [{ titulo: strings.dadosGerais }, { titulo: strings.dadosExame }, { titulo: strings.anexos }];

    //Esquemas de validação
    const schemaDadosExame = validationSchemaDadosExame(strings);
    const schemaInformacoesGerais = validationDadosGerais(strings);
    const schemaGeral = schemaInformacoesGerais.concat(schemaDadosExame);

    useSignalEffect(() => {
        let instituicoes = [];

        if (!temPerfilRBAC(usuario, PERFIL.MEDICO_SOLICITANTE)) {
            instituicoes = usuario.value.habilitacao.solicitacaoServico.filter(inst => inst.modalidades?.some(modalidade => modalidade.sigla === MODALIDADE.SIGLA)).map(inst => inst.id);
        } else {
            instituicoes = usuario.value.habilitacao.vinculo.map(inst => inst.id);
        }

        if (instituicoes.length) {
            const stringInsts = instituicoes.join();
            axios.get(`${global.gConfig.url_base_utilitarios}/equipamento?modalidade=${MODALIDADE.SIGLA}&instituicao=${stringInsts}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        equipamentos.value = response.data;
                    } else {
                        equipamentos.value = [];
                        erroEquipamento.value = true;
                    }
                })
                .catch(err => console.log(err));
        }

    });

    useSignalEffect(() => {
        if (erroEquipamento?.value) {
            const alertConfig = {
                title: strings.erro,
                message: strings.noaExisteEquipamento,
                type: 'error',
                open: true,
                options: [
                    {
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                            cancelarEnvio();
                        }
                    }
                ],
                onClose: () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    }
                    cancelarEnvio();
                }
            };
            alerta.value = alertConfig;
        }

    });

    const cancelarEnvio = () => {
        stepAtual.value = 0;
        if (formikRef?.current?.resetForm) {
            formikRef.current.resetForm();
        }
        anexos.value = [];
        resetFormulario();
    }

    const confirmarFecharModal = () => {
        const alertConfig = {
            title: strings.tituloModalCancelamento,
            message: strings.mensagemAlertaCancelamento,
            type: 'alert',
            open: true,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        cancelarEnvio();
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            ],
            onClose: () => {
                alerta.value = {
                    ...alerta.value,
                    open: false
                }
            }
        };
        alerta.value = alertConfig;
    }

    const finalizarEnvioImagens = () => {
        if (callbackFinalizarEnvio) {
            callbackFinalizarEnvio();
        }
        resetFormulario();
    }

    /**
     * Gera o protocolo do exame
     *
     */
    const imprimirProtocolo = (idExame) => {
        Utils.imprimirProtocoloFn({ id: idExame }, () => { });
    }

    /**
     * Gera o termo de autorização
     * 
     * @param {number} paciente
     */
    const imprimirTermo = (pac) => {
        Utils.imprimirTcleFn({ id: pac }, () => {
            progresso.value = false;
            finalizarEnvioImagens();
        });
    }

    useSignalEffect(() => {
        if (gerarProtocolo.value && exameEnviado?.value) {
            imprimirProtocolo(exameEnviado.value);
        }
    });

    useSignalEffect(() => {
        if (gerarTermo.value && paciente?.value) {
            imprimirTermo(paciente.value);
        }
    });

    const submitForm = (dados, setSubmitting) => {
        progresso.value = true;

        const formData = new FormData();
        formData.append('equipamento', dados.equipamento.id);
        if (dados.observacao && dados.observacao.trim() !== '') {
            formData.append('observacao', dados.observacao.trim());
        }
        formData.append('validarImagen', dados.validarImagem);
        formData.append('dadosExame', JSON.stringify(dados.dadosExame));
        formData.append('idSolicitacao', solicitacao.value.id);

        const { anexo, anexoExterno } = dados;

        if (anexo.length > 0) {
            anexo.forEach((a, index) => {
                if (a && (a instanceof File)) {
                    formData.append(`nome_anexos.${index}`, a.name);
                    formData.append(`anexo.${index}`, a);
                }
            });
        } else {
            formData.append('semAnexo', 1);
        }

        if (anexoExterno && anexoExterno instanceof File) {
            formData.append(`nome_anexos.${anexo.length}`, anexoExterno.name);
            formData.append(`anexo.${anexo.length}`, anexoExterno);
        }

        axios.post(`${global.gConfig.url_base_polissonografia}/exame`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if (response.status === HttpStatus.CREATED) {
                    // Armazena o identificador do exame gerado
                    exameEnviado.value = response.data.data.exame;
                    paciente.value = response.data.data.paciente;
                    ufSolicitante.value = response.data.data.uf;
                    // Gera o protocolo
                    gerarProtocolo.value = true;
                    // Gera o TCLE
                    gerarTermo.value = true;
                    anexos.value = [];
                } else {
                    progresso.value = false;

                    const alertConfig = {
                        title: strings.erro,
                        message: strings.mensagemErro,
                        type: 'error',
                        open: true,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                }

                            }
                        ],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    };
                    alerta.value = alertConfig;
                }
                stepAtual.value = 0;
            })
            .catch(err => {
                progresso.value = false;
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let titulo = strings.erro;
                let msgAlerta = '';

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResp = response.data;
                        let arrMensagem = [];
                        dadosResp.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        msgAlerta = msg;
                    } else {
                        msgAlerta = msg;
                    }
                } else {
                    msgAlerta = msg;
                }

                const alertConfig = {
                    title: titulo,
                    message: msgAlerta,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }

                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                };
                alerta.value = alertConfig;
            })
            .finally(() => {
                setSubmitting(false);
                gerarProtocolo.value = false;
                gerarTermo.value = false;
            });
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formikRef}
            validationSchema={schemaGeral}
            onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                submitForm(data, setSubmitting, resetForm);
            }}
        >
            {
                ({ handleSubmit }) => {
                    return (
                        <form noValidate onSubmit={handleSubmit}>
                            {
                                <ModalDadosGerais
                                    open={modalOpen}
                                    steps={steps}
                                    stepAtual={stepAtual}
                                    callbackFinalizado={handleSubmit}
                                    confirmarFecharModal={confirmarFecharModal}
                                    solicitacao={solicitacao.value}
                                    equipamentos={equipamentos}
                                />
                            }

                            <SttLoading
                                open={progresso.value}
                                text={mensagemProgresso}
                            />
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default EnvioImagens;