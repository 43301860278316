import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../../request';
import axios from 'axios';
import { MODALIDADE } from '../../common/Constants';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import { batch, signal, useSignalEffect } from '@preact/signals-react';
import alerta from '../../signals/alerta';
import { listar } from '../../signals/exame';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));

const motivos = signal([]);
const motivo = signal(null);
const priorizando = signal(false);

const Priorizar = (props) => {
    const { priorizar, idExame, idInstituicao, callback = () => { } } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    useSignalEffect(() => {
        // Carrega a lista de motivos de priorização de exames
        if (!motivos.value.length) {
            axios.get(`${EXAME_API_BASE_URL}/motivo-priorizacao`, { params: { siglaModalidade: MODALIDADE.SIGLA }, headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        motivos.value = response.data;
                    }
                })
                .catch(err => console.log(err));
        }
    }, []);

    const fecharPriorizar = () => {
        batch(() => {
            motivo.value = null;
            priorizar.value = false;
            callback();
        });
    }

    const selecionarMotivo = (event, novoMotivo) => {
        motivo.value = novoMotivo;
    }

    const priorizarExame = () => {
        priorizando.value = true;

        const dados = {
            exame: idExame,
            motivo: motivo.value.id,
            instituicao: idInstituicao,
            modalidade: MODALIDADE.DESCRICAO_PT
        }

        axios.post(`${EXAME_API_BASE_URL}/priorizar`, dados, { headers: getHeaders() })
            .then(() => {
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.sucesso,
                    type: 'success',
                    message: strings.examePriorizado,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            fecharPriorizar();
                            listar.value = true;
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        fecharPriorizar();
                        listar.value = true;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            })
            .catch(err => {
                console.log(err);

                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: strings.erroInvalidarExame,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            })
            .finally(() => {
                priorizando.value = false;
            });
    }

    return (
        <>
            <SttModal
                title={strings.priorizar}
                open={priorizar.value}
                outModalClose={fecharPriorizar}
                iconClose={fecharPriorizar}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        label: strings.motivo,
                                        required: true
                                    }}
                                    getOptionLabel={option => option.descricao}
                                    options={motivos.value}
                                    value={motivo.value}
                                    onChange={selecionarMotivo}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton
                            variant="contained"
                            color="primary"
                            disabled={!motivo}
                            onClick={() => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: true,
                                    title: strings.atencao,
                                    type: 'confirmation',
                                    message: strings.confirmarPriorizarExame,
                                    options: [
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                priorizarExame();
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        }
                                    ],
                                    onClose: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    }
                                };
                            }}
                        >
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharPriorizar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />

            <div className={classes.carregando}>
                <SttLoading
                    open={priorizando.value}
                    text={strings.priorizandoExame}
                />
            </div>
        </>
    );
}

export default Priorizar;
