import * as yup from 'yup'
import { SOLICITANTE, MEDICO, INSTITUICAO, OUTRO_MEDICO } from './fieldNames'


export default (strings) => {
    return yup.object().shape({
        [SOLICITANTE]: yup.object().shape({
            [MEDICO]: yup.object().shape({
                id: yup.number(),
                nome: yup.string()
            })
                .nullable()
                .required(strings.campoObrigatorio),
            [OUTRO_MEDICO]: yup.object().when(`${MEDICO}`, {
                is: (val) => {
                    return val && val.id === -1;
                },
                then: yup.object().shape({
                    id: yup.number(),
                    nome: yup.string()
                })
                    .nullable()
                    .required(strings.campoObrigatorio)
            }).nullable(),
            [INSTITUICAO]: yup.object().shape({
                id: yup.number(),
                nome: yup.string()
            })
                .nullable()
                .required(strings.campoObrigatorio)
        }).required()
    });
}